<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>            
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria'
                "
              >Secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Configurar disciplinas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Configurar
                  <span>disciplinas</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <!-- CONTEUDO PRINCIPAL -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Disciplinas"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
                          <h4 class="aluno_font_color">
                            Lista de disciplinas
                          </h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-8 text-right">
                          <button
                            class="btn btn-sm btn-danger mb-4 ml-2"
                            @click="showModal('modalExcluirTodasDisciplina')"
                          >
                            <small>Excluir tudo</small>
                          </button>
                          <button
                            class="btn btn-sm btn-primary mb-4 ml-2"
                            @click="showModal('modalBuscarDisciplinasConcurseiro')"
                          >
                            <small> <b-icon-plus />&nbsp; Disciplinas do concurseiro </small>
                          </button>
                          <button
                            class="btn btn-sm btn-success mb-4 ml-2"
                            @click="addNode"
                          >
                            <small> <b-icon-plus />&nbsp; Adicionar disciplina pai </small>
                          </button>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <vue-tree-list
                            :model="treeDisciplinas"
                            default-tree-node-name="Nova disciplina"
                            :default-expanded="false"
                            @click="onClick"
                            @change-name="onChangeName"
                            @delete-node="onDel"
                            @add-node="onAddNode"
                          >
                            <template v-slot:leafNameDisplay="slotProps">
                              <span>{{ slotProps.model.name }}</span>
                            </template>
                            <span
                              slot="addTreeNodeIcon"
                              title="Adicionar disciplina filha"
                              class="icon"
                            >➕</span>
                            <span
                              slot="addLeafNodeIcon"
                              class="icon d-none"
                            />
                            <span
                              slot="editNodeIcon"
                              title="Editar"
                              class="icon"
                            >📝</span>
                            <span
                              slot="delNodeIcon"
                              title="Excluir"
                              class="icon"
                            >❌</span>
                            <span
                              slot="treeNodeIcon"
                              class="icon"
                            >📚</span>
                          </vue-tree-list>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>

              <!-- /CONTEUDO PRINCIPAL -->
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirDisciplina"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="!fastDisciplinaExcluir.bloqueiaExclusao">
            Excluir disciplina {{ fastDisciplinaExcluir.name }}?
          </h4>
          <h4
            v-else
            class="text-danger"
          >
            Não é possível excluir
          </h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div v-if="!fastDisciplinaExcluir.bloqueiaExclusao">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-1"
                  @click.prevent="excluiNovaDisciplina()"
                >
                  Confirmar
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-secondary mr-1 ml-1"
                  @click.prevent="getFastDisciplinas()"
                >
                  Cancelar
                </button>
              </div>
              <div v-else>
                <small>Por questões de segurança primeiramente exclua as disciplinas filhas</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalBuscarDisciplinasConcurseiro"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Buscar disciplinas do concurseiro</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalBuscarDisciplinasConcurseiro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <p>Ao confirmar todas as suas disciplinas serão apagadas e subsituídas pelas disciplinas da base concurseiro.</p>
            </div>
            <div class="col-12">
              <div>
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-1"
                  @click.prevent="insereDisciplinasConcurseiro()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirTodasDisciplina"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir todas disciplinas?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTodasDisciplina')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-center">
              <p>Tem certeza que deseja excluir todas as disciplinas?</p>
            </div>
            <div class="col-12">
              <div>
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-1"
                  @click.prevent="excluiTodasDisciplinas()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { VueTreeList, Tree, TreeNode } from "vue-tree-list"; //https://github.com/ParadeTo/vue-tree-list#readme

export default {
  name: "HomeInternoSecretariaDisciplinas",
  components: {
    
    VueTreeList,
  },
  mixins: [methods],
  data: function() {
    return {
       modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Disciplinas
      treeDisciplinas: new Tree({
        name: "Primeira disciplina",
        id: 0,
        isLeaf: false,
        addLeafNodeDisabled: true,
      }),
      fastDisciplinaExcluir: [],
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
      .then(() => {
        if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
          this.getFastDisciplinas();
        } else {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Módulo não disponível para sua plataforma", "error");
        }
        
      })
      .catch((e) => {
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      });
    }
  },
  methods: {
    // Disciplinas
    async getFastDisciplinas() {
      this.fastDisciplinaExcluir = [];
      this.hideModal("modalExcluirDisciplina");
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/lista?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        //console.log("getFastDisciplinas", json);
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.name = e.descricao;
            e.id = e.id_plataforma_area_disciplina;
            e.isLeaf = false;
            e.addLeafNodeDisabled = true;
          });
          let arrayDisciplinasPai = obj.filter((d) => {
            if (d.id_plataforma_area_disciplina_pai == "0") return d;
          });
          arrayDisciplinasPai.forEach(function(d, index) {
            verificaNivel2(d, index);
          });

          function verificaNivel2(d, index) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!arrayDisciplinasPai[index].children) arrayDisciplinasPai[index].children = [];
                arrayDisciplinasPai[index].children.push(d2);

                verificaNivelUltimos(d2);
              }
            });
          }

          function verificaNivelUltimos(d) {
            obj.forEach(function(d2) {
              if (d.id_plataforma_area_disciplina == d2.id_plataforma_area_disciplina_pai) {
                if (!d.children) d.children = [];
                d.children.push(d2);
                verificaNivelUltimos(d2);
              }
            });
          }

          //console.log("Array original", obj);
          //console.log("Array formatado", arrayDisciplinasPai);

          this.treeDisciplinas = new Tree(arrayDisciplinasPai);
        } else {
          this.treeDisciplinas = new Tree({
            name: "Primeira disciplina",
            id: 0,
            isLeaf: false,
            addLeafNodeDisabled: true,
          });
        }
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async insereNovaDisciplina(objDisciplina) {
      let fast_plataforma_disciplinas = {
        id_plataforma_area_disciplina: 0,
        descricao: objDisciplina.name,
        id_plataforma_area_disciplina_pai: objDisciplina.id_plataforma_area_disciplina_pai,
        nivelzero: 0,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/insere", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_disciplinas)));
        let json = await resp.json();
        // Loading
        this.$store.state.fastCarregando = false;
        
        this.exibeToasty("Disciplinas salvas com sucesso", "success");
        this.getFastDisciplinas();
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao salvar alterações", "error");
      }
    },
    async atualizaDisciplina(objDisciplina) {
      let fast_plataforma_disciplinas = {
        id_plataforma_area_disciplina: objDisciplina.id_plataforma_area_disciplina,
        descricao: objDisciplina.name,
        id_plataforma_area_disciplina_pai: objDisciplina.id_plataforma_area_disciplina_pai,
        nivelzero: 0,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/atualiza", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_disciplinas)));
        let json = await resp.json();
        this.exibeToasty("Alterações salvas com sucesso", "success");
        this.getFastDisciplinas();
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao salvar alterações", "error");
      }
    },
    async excluiNovaDisciplina() {
      let fast_plataforma_disciplinas = {
        id_plataforma_area_disciplina: this.fastDisciplinaExcluir.id_plataforma_area_disciplina,
        descricao: '',
        id_plataforma_area_disciplina_pai: 0,
        nivelzero: 0,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/exclui", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_disciplinas)));
        let json = await resp.json();
        // Loading
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Disciplina excluída com sucesso", "success");
        this.getFastDisciplinas();
      } catch (e) {
        console.log("Erro", e);
        this.exibeToasty("Erro ao excluir disciplina", "error");
      }
    },
    async excluiTodasDisciplinas() {
      this.$store.state.fastCarregando = true;
      let fast_plataforma_disciplinas = {
        id_plataforma_area_disciplina: this.fastDisciplinaExcluir.id_plataforma_area_disciplina,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/limpa", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_disciplinas)));
        let json = await resp.json();
        // Loading
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Disciplinas excluídas com sucesso", "success");
        this.hideModal("modalExcluirTodasDisciplina");
        this.getFastDisciplinas();
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
        this.exibeToasty("Erro ao excluir disciplinas", "error");
      }
    },
    async insereDisciplinasConcurseiro() {
      this.$store.state.fastCarregando = true;
      let fast_plataforma_disciplinas = {
        id_plataforma_area_disciplina: this.fastDisciplinaExcluir.id_plataforma_area_disciplina,
        id_plataforma: this.$route.params.id_plataforma,
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_disciplinas/insere_concurseiro", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_disciplinas)));
        let json = await resp.json();
        // Loading
        this.exibeToasty("Disciplinas inseridas com sucesso", "success");
        this.hideModal("modalBuscarDisciplinasConcurseiro");
        this.getFastDisciplinas();
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
        this.exibeToasty("Erro ao inserir disciplinas", "error");
      }
    },
    onDel(node) {
      console.log(node);
      if (node.id_plataforma_area_disciplina) {
        this.fastDisciplinaExcluir = node;
        if (node.children) {
          if (node.children.length) {
            // Não pode excluir disciplina pai sem excluir as filhas
            this.fastDisciplinaExcluir.bloqueiaExclusao = true;
          }
        }
        this.showModal("modalExcluirDisciplina");
      } else {
        node.remove();
      }
    },
    getElementTreeById(array, id) {
      if (array) {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id == id) {
            return array[i];
          }
          let found = this.getElementTreeById(array[i].children, id);
          if (found) return found;
        }
      }
    },
    onChangeName(e) {
      if (e.eventType) {
        if (e.eventType == "blur") {
          let objDisciplina = this.getElementTreeById(this.treeDisciplinas.children, e.id);

          if (!objDisciplina.parent.id_plataforma_area_disciplina) objDisciplina.id_plataforma_area_disciplina_pai = 0;
          else objDisciplina.id_plataforma_area_disciplina_pai = objDisciplina.parent.id_plataforma_area_disciplina;

          if (!objDisciplina.id_plataforma_area_disciplina) this.insereNovaDisciplina(objDisciplina);
          else this.atualizaDisciplina(objDisciplina);
        }
      }
    },
    onAddNode(objDisciplina) {
      //objDisciplina.id_plataforma_area_disciplina_pai = objDisciplina.parent.id_plataforma_area_disciplina;
      //objDisciplina.descricao = objDisciplina.name;
    },
    onClick(params) {
      console.log(params);
    },
    addNode() {
      var node = new TreeNode({
        descricao: "Nova disciplina",
        name: "Nova disciplina",
        isLeaf: false,
        addLeafNodeDisabled: true,
        id_plataforma_area_disciplina: 0,
        nivel_zero: 0,
      });

      if (!this.treeDisciplinas.children) this.treeDisciplinas.children = [];
      this.treeDisciplinas.addChildren(node);
    },
    getNewTree() {
      console.log(this.treeDisciplinas.children);
      this.treeDisciplinas.children.forEach((e) => {
        if (!e.id_plataforma_area_disciplina) {
          //if (!e.parent.id_plataforma_area_disciplina) e.parent.id_plataforma_area_disciplina = 0;
          console.log("Salvar disciplina pai no banco", e.name);
          this.insereNovaDisciplina(e);
          // Loading
          this.$store.state.fastCarregando = true;
        }
        if (e.children) {
          if (e.children.length) {
            this.buscaDisciplinaRecursiva(e.children);
          }
        }
      });
    },
    buscaDisciplinaRecursiva(objDisciplina) {
      objDisciplina.forEach((e) => {
        if (!e.id_plataforma_area_disciplina) {
          console.log("Salvar disciplina no banco:", e.name, ". O pai é ", e.parent.id_plataforma_area_disciplina);
        }
        if (e.children) {
          if (e.children.length) {
            this.buscaDisciplinaRecursiva(e.children);
          }
        }
      });
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Tree */
.icon:hover {
  cursor: pointer;
}

.muted {
  color: gray;
  font-size: 80%;
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
